<template>
  <div class="row justify-content-center px-8 px-lg-10">
    <div class="col-xl-12 col-xxl-7 justify-content-center">
      <!--begin::Form Wizard-->
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="form" @submit.prevent="handleSubmit(updateEmployees)">
          <!--begin::Step 1-->
          <div data-wizard-type="step-content" data-wizard-state="current">
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label"
                    >{{ $t('commons.first_name') }}</label
                  >
                  <validation-provider rules="required" :name="$t('commons.first_name')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <input
                      class="form-control"
                      :name="$t('commons.first_name')"
                      type="text"
                      v-model="employees.name"
                      :class="classes"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label"
                    >{{ $t('commons.last_name') }}</label
                  >
                    <validation-provider rules="required" :name="$t('commons.last_name')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                      <input
                        class="form-control"
                        :name="$t('commons.last_name')"
                        type="text"
                        v-model="employees.surname"
                        :class="classes"
                      />
                      <span class="error__message">{{ errors[0] }}</span>
                    </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label"
                    >{{ $t('commons.mobile_phone') }}</label
                  >
                  <validation-provider rules="required" :name="$t('commons.mobile_phone')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <div class="input-group" :class="classes">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><i class="la la-phone"></i
                        ></span>
                      </div>
                      <input
                        type="number"
                        value=""
                        :name="$t('commons.mobile_phone')"
                        id="phoneValid"
                        minlength="10"
                        :placeholder="$t('commons.phone_example', {value: ' - 533xxxxxxx'})"
                        required="required"
                        aria-describedby="basic-addon1"
                        class="form-control"
                        :class="classes"
                        v-model="employees.phone"
                      />
                    <span class="error__message">{{ errors[0] }}</span>
                    </div>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label"
                    >{{ $t('commons.e_mail') }}</label
                  >
                    <validation-provider rules="required" :name="$t('commons.e_mail')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                      <div class="input-group" :class="classes">
                        <div class="input-group-prepend">
                          <span class="input-group-text"
                            ><i class="la la-at"></i
                          ></span>
                        </div>
                          <input
                            type="text"
                            class="form-control"
                            :name="$t('commons.e_mail')"
                            :placeholder="$t('commons.e_mail')"
                            v-model="employees.email"
                            :class="classes"
                          />
                          <span class="error__message">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                    <div v-else class="col-lg-9 col-xl-9">
                      <Skeleton height="38px" />
                    </div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.password') }}</label>
                  <validation-provider rules="required" :name="$t('commons.password')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <input
                      class="form-control"
                      :name="$t('commons.password')"
                      type="text"
                      v-model="employees.password"
                      :class="classes"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.department') }}</label>
                  <validation-provider rules="required" :name="$t('commons.department')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <multiselect
                        v-model="selectedDepartment"
                        :options="departments"
                        :searchable="false"
                        :allow-empty="false"
                        open-direction="bottom"
                        @input="onChangeDepartment"
                        deselect-label=''
                        select-label=''
                        selected-label=''
                        track-by='label'
                        label='label'
                        :placeholder="$t('commons.choosen_department')"
                        :class="classes"
                    >
                      <template slot='singleLabel' slot-scope='{ option }'>{{ $t(`service.${option.label}`) }}</template>
                      <template slot='option' slot-scope='{ option }'>{{ $t(`service.${option.label}`) }}</template>
                      <template slot='noOptions'>{{ 'List is empty' }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.user_id') }}</label>
                  <div class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <input
                      class="form-control"
                      :name="$t('commons.user_id')"
                      type="number"
                      v-model="disabledInputUserId"
                      disabled
                    />
                  </div>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('employees.is_responsible') }}</label>
                  <validation-provider rules="required" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <div class="radio-inline">
                      <label class="radio" for="no">
                        <input
                          type="radio"
                          id="no"
                          :name="$t('commons.responsible')"
                          value="0"
                          v-model="employees.is_responsible"
                          @input="isResponsibleNo"
                        />
                        <span :class="!employees.is_responsible ? classes : null"></span>{{ $t('commons.no') }}
                      </label>
                      <label class="radio" for="yes">
                        <input
                          type="radio"
                          id="yes"
                          :name="$t('commons.responsible')"
                          value="1"
                          v-model="employees.is_responsible"
                        />
                        <span :class="!employees.is_responsible ? classes : null"></span>{{ $t('commons.yes') }}
                      </label>
                    </div>
                    <span>{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                  </div>
                </div>


                <div class="form-group row" v-if="isResponsible">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('employees.institutions_to_be_responsible_with') }}</label>
                  <div class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <multiselect
                      v-model="selectedInstitutions"
                      :options="institutions"
                      :searchable="false"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      open-direction="bottom"
                      deselect-label=''
                      select-label=''
                      selected-label=''
                      track-by='name'
                      label='name'
                      :placeholder="$t('employees.institutions_to_be_responsible_with')"
                    >
                      <template slot='singleLabel' slot-scope='{ option }'>{{ option.name }}</template>
                      <template slot='option' slot-scope='{ option }'>{{ option.name }}</template>
                      <template slot='noOptions'>{{ 'List is empty' }}</template>
                    </multiselect>
                  </div>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.status') }}</label>
                  <validation-provider rules="required" :name="$t('commons.status')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <multiselect
                        v-model="selectedStatus"
                        :options="statuses"
                        :searchable="false"
                        :allow-empty="false"
                        open-direction="bottom"
                        @input="onChangeStatus"
                        deselect-label=''
                        select-label=''
                        selected-label=''
                        track-by='label'
                        label='label'
                        :placeholder="$t('commons.choosen_status')"
                        :class="classes"
                    >
                      <template slot='singleLabel' slot-scope='{ option }'>{{ $t(`service.${option.label}`) }}</template>
                      <template slot='option' slot-scope='{ option }'>{{ $t(`service.${option.label}`) }}</template>
                      <template slot='noOptions'>{{ 'List is empty' }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!--begin::Actions-->
          <div class="d-flex justify-content-end border-top mt-5 pt-10">
            <button
              type="submit"
              class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
            >
              {{ $t('commons.submit') }}
            </button>
          </div>
          <!--end::Actions-->
        </form>
      </ValidationObserver>
      <!--end::Form Wizard-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { Skeleton } from 'vue-loading-skeleton';

export default {
  name: "UpdateEmployees",
  components: {
    Multiselect,
    Skeleton
  },
  data() {
    return {
      employees: {
        name: "",
        surname: "",
        phone: "",
        email: "",
        password: "",
        department_id: "",
        type: "",
        status: "",
        user_id: "",
        is_responsible: 0,
        institutionIds: []
      },
      employeeId: this.$route.params.id,
      selectedDepartment: null,
      selectedStatus: null,
      disabledInputUserId: '',
      selectedInstitutions: null,

    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.employees.isLoading,
      employesEditData: (state) => state.employees.employees_new_get,
      statuses: function() {
          return this.employesEditData?.statuses || [];
      },
      departments: function() {
          return this.employesEditData?.departments || [];
      },
      employee: function() {
          return this.employesEditData.employee;
      },
      institutions: function() {
          return this.employesEditData.institutions;
      },
      isResponsible() {
        return this.employees.is_responsible?.toString() === '1'
      }
    }),
  },
  methods: {
    ...mapActions("employees", ["EDIT_EMPLOYESS", "UPDATE_EMPLOYESS"]),
    updateEmployees() {
      if(this.isResponsible && this.selectedInstitutions.length > 0) {
        this.employees.institutionIds = this.selectedInstitutions.map(val => val.id)
      }
      let data = {
        id: this.employeeId,
        data: this.employees,
      };
      // console.log(data);
      this.UPDATE_EMPLOYESS(data).then(() => {
        this.$router.push({ name: "Employees" });
      })
    },
    onChangeDepartment() {
      this.employees.department_id = this.selectedDepartment.id
    },
    onChangeStatus() {
      this.employees.status = this.selectedStatus.id
    },
    isResponsibleNo() {
      this.employees.institutionIds = []
    },
  },
  created() {
    this.EDIT_EMPLOYESS(this.employeeId).then(() => {
      this.employees = {
        ...this.employees,
        name: this.employee?.name || "",
        surname: this.employee?.surname || "",
        phone: this.employee?.phone || "",
        email: this.employee?.email || "",
        department_id: this.employee?.department_id || "",
        status: this.employee?.status || "",
        user_id: this.employee?.user_id || "",
        is_responsible: this.employee?.is_responsible || 0,
        institutionIds: this.employesEditData?.institutionIds || []
      };
      this.disabledInputUserId = this.employee?.user_id || ""
      if(this.departments.length > 0) {
        this.selectedDepartment = this.departments.find(val => String(val.id) === String(this.employee?.department_id))
      }
      if(this.statuses.length > 0) {
        this.selectedStatus = this.statuses.find(val => String(val.id) === String(this.employee?.status))
      }
      if(this.institutions.length > 0 && this.employesEditData?.institutionIds?.length > 0) {
        this.selectedInstitutions = [...this.institutions].filter(item => this.employesEditData?.institutionIds.includes(item.id)) // set multiselect
      }
    });
  },
};
</script>
